import React from 'react';
import { graphql } from 'gatsby';

import CardTable from '../components/Table/CardTable';
import GlobalActivity from '../components/GlobalActivity/GlobalActivity';
import PageTitle from '../components/layout/PageTitle/PageTitle';
import SEO from '../components/seo';

import regionsReaCapacity from '../data/reg_rea_capa.json';

const RegionPageTemplate = ({ data, pageContext, franceStats }) => {

  const allSexData = data.allSex.nodes;

  const { regId } = pageContext;
  const { departments, regions } = franceStats;

  const reaCapacity = regionsReaCapacity[regId].rea_capacity;

  const departmentsByRegionAndDate = {};

  allSexData.forEach((line) => { // { dep, jour, dc, rea, rad, hosp }
    if (line.dep) {
      const regionCode = departments[line.dep].codeRegion;
      departmentsByRegionAndDate[regionCode] = departmentsByRegionAndDate[regionCode] || {};
      departmentsByRegionAndDate[regionCode][line.jour] = departmentsByRegionAndDate[regionCode][line.jour] || [];
      departmentsByRegionAndDate[regionCode][line.jour].push(line);
    }
  });

  const dataByRegionDaily = {};

  Object.keys(departmentsByRegionAndDate).forEach((regionId) => {
    Object.keys(departmentsByRegionAndDate[regionId]).forEach((day) => {
      let sum = {
        dc: 0,
        rea: 0,
        hosp: 0,
        rad: 0,
      };

      departmentsByRegionAndDate[regionId][day].forEach((dep) => {
        sum = {
          dc: sum.dc + parseInt(dep.dc, 10),
          hosp: sum.hosp + parseInt(dep.hosp, 10),
          rea: sum.rea + parseInt(dep.rea, 10),
          rad: sum.rad + parseInt(dep.rad, 10),
        }
      });

      dataByRegionDaily[regionId] = dataByRegionDaily[regionId] || {};
      dataByRegionDaily[regionId][day] = {
        ...sum,
        total: sum.dc + sum.hosp + sum.rea + sum.rad,
        jour: day,
      };
    });
  });

  const selectedRegionDataByDate = dataByRegionDaily[regId];

  const selectedRegionData = Object.values(selectedRegionDataByDate);
  

  const todayData = {
    dc: selectedRegionData[selectedRegionData.length - 1].dc,
    hosp: selectedRegionData[selectedRegionData.length - 1].hosp,
    rea: selectedRegionData[selectedRegionData.length - 1].rea,
    rad: selectedRegionData[selectedRegionData.length - 1].rad,
  };
  
  const yesterdayData = {
    dc: selectedRegionData[selectedRegionData.length - 2].dc,
    hosp: selectedRegionData[selectedRegionData.length - 2].hosp,
    rea: selectedRegionData[selectedRegionData.length - 2].rea,
    rad: selectedRegionData[selectedRegionData.length - 2].rad,
  }

  const globalStats = {
    active: todayData.hosp + todayData.rea,
    cases: todayData.dc + todayData.hosp + todayData.rea + todayData.rad,
    // casesPerOneMillion
    // country
    critical: todayData.rea,
    deaths: todayData.dc,
    // deathsPerOneMillion
    recovered: todayData.rad,
    todayCases: (todayData.dc + todayData.hosp + todayData.rea + todayData.rad) - (yesterdayData.dc + yesterdayData.hosp + yesterdayData.rea + yesterdayData.rad),
    todayDeaths: todayData.dc - yesterdayData.dc,
  };

  const allDates = selectedRegionData.map((item) => item.jour);
  const lastDate = allDates[allDates.length - 1];


  return (
    <>
      <SEO title={franceStats.regions[regId].nom} />

      <PageTitle title={`Région ${regions[regId].nom}`} />

      <GlobalActivity
        data={{ allDates, lastDate, dataByDate: selectedRegionDataByDate, reaCapacity }}
        globalStats={globalStats}
        cumulChartItem={['total', 'dc', 'hosp', 'rea', 'rad']}
        dailyChartItems={['dc', 'hosp', 'rea', 'rad']}
      />

      <CardTable
        headers={['Jour', 'Total', 'Décès', 'Hosp', 'Réa', 'Rétablis']}
        dataKeys={['jour', 'total', 'dc', 'hosp', 'rea', 'rad']}
        data={selectedRegionData.reverse()}
      />
    </>
  );
};

export default RegionPageTemplate;

export const query = graphql`
  query RegionPageQuery {
    allSex: allDataCsv(filter: {sexe: {eq: "0"}}) {
      nodes {
        dc
        hosp
        dep
        jour
        rad
        rea
      }
    }
    allDepartments {
      nodes {
        code
        codeRegion
        nom
        region {
          code
          nom
        }
      }
    }
  }
`;
